// Modules
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { Modal, ModalBody, Table } from 'reactstrap'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
// Actions
import { uploadCSVFile } from '../../../../../../../ducks/Awards.ducks'
// Helpers
import { config } from '../../../../../../../config'
// Components
import Dropzone from './components/Dropzone'
import ResponseDetails from './components/ResponseDetails'
// Assets
import IconPlus from '../../../../../../../assets/image/icon-dark-plus.svg'
import IconDownloadCsv from '../../../../../../../assets/image/icon-download-csv.svg'
import IconFilePreview from '../../../../../../../assets/image/icon-file-preview.svg'
import IconToastError from '../../../../../../../assets/image/icon-toast-error.png'
import IconModalClose from '../../../../../../../assets/image/icon-dark-close.svg'

const UploadCsv = ({ t, badgeId }) => {
  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState(false)
  const [uploadButtonShow, setUploadButtonShow] = useState(true)
  const [files, setFiles] = useState([])
  const [csvUploadResponse, setCsvUploadResponse] = useState(null)

  const [csvState, setCsvState] = useState({
    isLoading: false,
    columns: [],
    errors: []
  })

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    multiple: false,
    accept: '.csv, application/vnd.ms-excel, text/csv,.numbers',
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) => {
          return Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        })
      )
    }
  })

  const toggleShowModal = () => {
    setShowModal((prevState) => !prevState)
  }

  const handleCSVUpload = () => {
    setCsvState((prevState) => ({
      ...prevState,
      isLoading: true
    }))
    setUploadButtonShow(false)

    uploadCSVFile &&
      uploadCSVFile({
        data: { csv: files[0], badgeId },
        dispatch,
        successCb: (data) => {
          setCsvUploadResponse(data)

          if (data.message.length > 0) {
            const csvData = data.message
              .sort((a, b) => a.line - b.line)
              .map((element) => ({
                lineNumber: element.line,
                error: element.error
              }))
            setCsvState((prevState) => ({
              ...prevState,
              columns: [
                ...prevState.columns,
                {
                  name: t('award-badge-page.line-number'),
                  label: t('award-badge-page.line-number-label')
                },
                {
                  name: t('award-badge-page.error-text'),
                  label: t('award-badge-page.line-number-error')
                }
              ],
              errors: csvData
            }))
          }
          setCsvState((prevState) => ({
            ...prevState,
            isLoading: false
          }))
        },
        errorCb: (e) => {
          setCsvUploadResponse(false)
          toast.error(e, {
            icon: <img src={IconToastError} className="img-fluid" alt="images" />
          })
        }
      })
  }

  const handleDeleteCSVUpload = async (i) => {
    const newFiles = [...files]
    newFiles.splice(i, 1)
    setFiles(newFiles)

    setCsvUploadResponse(null)
    setUploadButtonShow(true)

    setCsvState((prevState) => ({
      ...prevState,
      columns: [],
      errors: []
    }))
  }

  return (
    <>
      {files.length < 1 ? (
        <Dropzone
          translate={t}
          config={config}
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          icons={{ add: IconPlus, download: IconDownloadCsv }}
        />
      ) : (
        <aside>
          <ResponseDetails
            translate={t}
            files={files}
            handleDeleteCSVUpload={handleDeleteCSVUpload}
            isLoading={csvState.isLoading}
            uploadButtonShow={uploadButtonShow}
            handleCSVUpload={handleCSVUpload}
            csvUploadResponse={csvUploadResponse}
            toggleModal={toggleShowModal}
            icons={{ closeIcon: IconModalClose, previewIcon: IconFilePreview }}
          />
        </aside>
      )}

      <Modal
        isOpen={showModal}
        toggle={toggleShowModal}
        className="modal-dialog-centered common-modal preview-modal"
        backdropClassName="op-35">
        <ModalBody>
          <div className="close-modal pointer" onClick={() => setShowModal(false)}>
            <img src={IconModalClose} className="img-fluid" alt="images" />
          </div>
          {csvUploadResponse !== null && csvUploadResponse !== false ? (
            csvUploadResponse.data.failed !== 0 ? (
              <Table responsive hover borderless>
                <thead>
                  <tr>
                    {csvState.columns.length !== 0
                      ? csvState.columns.map((items, i) => <th key={i}>{items.label}</th>)
                      : null}
                  </tr>
                </thead>
                <tbody>
                  {csvState.errors.length > 0
                    ? csvState.errors.map((err, index) => {
                        const messages = err.error
                        return (
                          <tr key={index}>
                            <td>
                              <span>{err.lineNumber}</span>
                            </td>
                            <td>
                              {messages.map((message) => (
                                <span>{message}</span>
                              ))}
                            </td>
                          </tr>
                        )
                      })
                    : null}
                </tbody>
              </Table>
            ) : null
          ) : null}
        </ModalBody>
      </Modal>
    </>
  )
}

UploadCsv.propTypes = {
  t: PropTypes.func,
  badgeId: PropTypes.string
}

export default UploadCsv
