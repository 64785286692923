// Modules
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
// Actions
import { useAuth } from '../../../ducks/Auth.ducks'
import { getBadges } from '../../../ducks/Badges.ducks'
import { getIssuers } from '../../../ducks/Issuers.ducks'
// Components
import { HeaderTitle } from '../../../components'
import BadgesListing from './BadgesListing'

const BadgesListingMain = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const [bulkSelection, setBulkSelection] = useState([])

  const { authState: { auth } = {} } = useAuth()
  const { badges, isDataEmpty } = useSelector((state) => state.badges)
  const { issuersList: issuers } = useSelector((state) => state.issuers)

  // Filter out from the list the already opened issuer
  const filteredIssuers = issuers
    .filter((issuer) => issuer.id !== location.state.id)
    .map((issuer) => ({
      id: issuer.id,
      label: issuer.name,
      value: issuer.name
    }))

  useEffect(() => {
    if (!location.state) {
      history.goBack()
    }

    const asyncApiCall = async () => {
      await Promise.all([dispatch(getIssuers()), getBadges({ id: location.state.id, dispatch })])
    }

    asyncApiCall()
  }, [getBadges, getIssuers])

  return (
    <>
      <Helmet>
        <title>IHK - Aussteller Details</title>
      </Helmet>
      <section
        id="scrollData"
        className={classNames('mid-section', {
          'bulk-container': bulkSelection.length > 0
        })}>
        <div className="container-fluid common-container">
          <HeaderTitle title={t('page-title.main-badge')} />
          {issuers.length > 0 && (
            <BadgesListing
              t={t}
              auth={auth}
              bulkSelection={bulkSelection}
              setBulkSelection={setBulkSelection}
              issuers={filteredIssuers}
              badges={badges}
              isDataEmpty={isDataEmpty}
            />
          )}
        </div>
      </section>
    </>
  )
}

export default BadgesListingMain
